<template>
 <div class="outer-wrapper">
    <div class="ele" v-for="(ele,index) in menuData.filter(val=>val.ifShow)" :key="index">
      <div class="ele-inner">
        <p class="title">{{ele.text}}</p>
        <div class="inner-wrapper">
          <div class="item" v-for="(item,subindex) in ele.children.filter(val=>val.ifShow)" :key="subindex">
            <div class="item-inner" @click="linkTo(item.to)">
              <p>{{item.text}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
 </div>
</template>
<script>
let authority = [];
let authData = JSON.parse(localStorage.getItem("authData"));
if (authData) {
  authority = authData.barRights;
}
export default {
  name: "index",
  components: {
  },
  data() {
    return {
      defaultMenuData: [
          {
          id: "0",
          text: "用户权限",
          ifShow:true,
          icon: "ios-people",
          children: [
            { text: "用户管理", id: "0-0", to: "userManage",ifShow:true, },
            { text: "权限管理", id: "0-1", to: "roleManage",ifShow:true, },
          ]
        },
        {
          id: "1",
          text: "网站数据",
          ifShow:true,
          icon: "ios-subway",
          children: [
            { text: "资质证书", id: "1-1", to: "certificateManage",ifShow:true, },
            { text: "发展历程", id: "1-2", to: "historyManage",ifShow:true, },
            { text: "新闻中心", id: "1-3", to: "newsManage",ifShow:true, },
            { text: "我们的服务", id: "1-4", to: "servicesManage",ifShow:true, },
            { text: "您的行业", id: "1-8", to: "industriesManage",ifShow:true, },
            { text: "文件中心", id: "1-5", to: "archiveManage",ifShow:true, },
            { text: "办事处", id: "1-6", to: "officesManage",ifShow:true, },
            { text: "菁英招募", id: "1-7", to: "jobsManage",ifShow:true, },
          ]
        },
        
      ]
      
    };
  },
  computed:{
      menuData:function(){
          let newData = JSON.parse(JSON.stringify(this.defaultMenuData));
        // return newData;//测试
          if(authority.indexOf('super_all')!==-1){
              return newData;
          }else if(authority.length===0){
              return [];
          }else{
          for(let i=0;i<this.defaultMenuData.length;i++){
              for(let j=0;j<this.defaultMenuData[i].children.length;j++){
                  if(authority.indexOf(this.defaultMenuData[i].children[j].to)===-1){
                      newData[i].children[j].ifShow = false;
                  }else{
                    newData[i].children[j].ifShow = true;
                  }
              }
              if(newData[i].children.every(ele=>{return !ele.ifShow;})){
                  newData[i].ifShow = false;
              }else{
                newData[i].ifShow = true;
              }
          }
          return newData;
          }
      },
  },
  props: {
    
  },
  methods: {
   linkTo(to){
    this.$router.push({name:to});
   }
  },
};
</script>

<style scoped>
.outer-wrapper{
    min-height: 800px;
    padding: 100px 0;
}
.ele{
  width:60%;
  margin:20px auto;
}

.title{
  font-size: 16px;
  color:#999;
  font-weight: 600;
  padding: 10px 20px;
}
.inner-wrapper{
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: left;
  flex-wrap: wrap;
}
.item{
  width:300px;
  height: 300px;
  padding: 30px;
}
.item-inner{
  width:100%;
  height:100%;
  border:2px solid #eee;
  border-radius: 5px;
  cursor: pointer;
  color:cadetblue;
  font-size: 26px;
  font-weight: 600;
  display: flex;
  display: -webkit-flex;
  display: -ms-flexbox;
  justify-content: center;
  align-items: center;

}
.item-inner:hover{
  box-shadow: 3px 3px 5px #aaa;
}
</style>